import React from 'react'
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import Banner from '../../components/Industries/Banner'
import FeaturedServices from '../../components/Industries/FeaturedServices'
import AboutArea from '../../components/Industries/AboutArea'
import FunFacts from '../../components/Industries/FunFacts'
import OurServices from '../../components/Industries/OurServices'
import CaseStudy from '../../components/Industries/CaseStudy'
import WhyChooseUs from '../../components/Industries/WhyChooseUs'
import Testimonials from '../../components/Industries/Testimonials'
import Pricing from '../../components/Industries/Pricing'
import AnalysisForm from '../../components/Industries/AnalysisForm'
import Partner from '../../components/Industries/Partner'
import OurBlog from '../../components/Industries/OurBlog'
import TeamMembers from '../../components/Industries/TeamMembers'

const SeoAgency = () => {
    return (
        <Layout>
            <Navbar />
            <Banner />
            <FeaturedServices />
            <AboutArea />
            <FunFacts />
            <OurServices />
            <CaseStudy />
            <WhyChooseUs />
            <Testimonials />
            <Pricing />
            <TeamMembers />
            <AnalysisForm />
            <Partner />
            <OurBlog />
            <Footer />
        </Layout>
    )
}

export default SeoAgency
